<template>
  <div id="HomeAbout">
    <h2 class="text-theme uppercase text-3xl">About</h2>

    <div class="content-about w-11/12">
      <div>
        <img class="qtm-comp" src="@/assets/graphics/quantum-computer.svg" />
      </div>
      <div class="flex justify-center items-center">
        <img src="@/assets/graphics/divider.svg" />
      </div>
      <div>
        <h3 class="main-text"><span class="text-theme">Learn Today;</span> Build Tomrorow</h3>
        <p class="text-white text-left text-xs md:text-lg mb-5">
          Quantumfy is your source to learn about the emerging technology surrounding quantum computing, and associated topics through expressive infographics! We started out as mainly social media pages but we seek to expand beyond to forever provide awesome content and build additional resources that you all can use to become empowered within the Quantum Verse!
        </p>
        <a href="/about" class="select-btn select-btn2 border border-blue-700 p-3 mt-5 text-blue-700" >About</a>
      </div>
    </div>

  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "HomeAbout",
  components:{
  },
  data(){
    return{
      roadmap_list: [
        {icon: require('@/assets/logos/learn.png'), name: 'Learn', link: '/learn'},
        {icon: require('@/assets/logos/learn.png'), name: 'Definitions', link: '/definitions'},
        {icon: require('@/assets/logos/snippets.png'), name: 'News Snippets', link: '/snippets'},
      ]
    }
  },
  methods: {
    gotoLink(link){
      window.open(`${link}`, '_self');
    }
  },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

#HomeAbout{
  width: 100%;
  position: relative;
  min-height: 90vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.content-about{
  display: grid;
    grid-template-columns: 10fr 1fr 10fr;
  grid-template-rows: 2fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
}
.main-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: .4rem;
  font-weight: 500;
  font-size: 2rem;
  color:#fff;
}
.select-btn{
  background-color: var(--primary);
  border-radius: 45px;
  padding: .75rem 2.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.select-btn2{
  border: 2px solid var(--primary);
  border-radius: 45px;
  background: transparent;
  color: var(--primary);
}
.qtm-comp{
  width: 80%;
  margin: 0 auto;
}
.service-area{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
h1{
  font-family: all-round-gothic, sans-serif;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
}
.brand{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.brand h1{
  font-size: 3rem;
  transition:1s;
  color:#fff;
}
.brand img{
 width: 9rem;
 transition: 1s;
}
.service-box{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition:1s;
}
@media only screen and (max-width: 900px) {
  #HomeAbout{
    min-height: 70vh;
  }
}
</style>
