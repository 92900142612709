<template>
  <div id="SnippetsOnHome">
    <h2 class="text-theme uppercase text-xl md:text-3xl">KEEPING UP WITH QUANTUM</h2>

    <div class="flex flex-col justify-evenly items-center text-left w-8/12">
        <img class="w-10/12 md:w-6/12 mx-auto" src="@/assets/graphics/news-sample.svg" />
        <p class="w-5/6 md:w-6/12 text-white text-center text-sm md:text-lg my-5">
          There's a lot that occurs in the quantum space, lucky for you we curate news sources from the space into a telegram channel so that you can always stay up to date! We seek to expand upon this in the future!
        </p>
        <a class="select-btn" href="https://t.me/quantumfy">Join Now</a>
    </div>

  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "Hero",
  components:{
  },
  data(){
    return{
    }
  },
  methods: {
  },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

#SnippetsOnHome{
  width: 100%;
  position: relative;
  min-height: 90vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: .4rem;
  font-weight: 500;
  font-size: 2rem;
  color:#fff;
}
.select-btn{
  background-color: var(--primary);
  border-radius: 45px;
  padding: .75rem 2.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
@media only screen and (max-width: 900px) {
  #SnippetsOnHome{
    min-height: 70vh;
  }
}
</style>
