<template>
  <div id="Snippets">
    <SnippetsHero />

    <section class="btn-area flex">
      <!-- <div>
        <img src="@/assets/graphics/news-sample.svg" />
      </div> -->
      <div>
        <p class="subhead text-white"><span class="text-theme">Breaking Down</span> the Emojis</p>
        <img src="@/assets/graphics/news-sample2.svg" />
    </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios'
import SnippetsHero from '@/components/news-snippets/SnippetsHero.vue'

export default {
  name: "Snippets",
  components:{
    SnippetsHero,
  },
  data(){
    return{
    }
  },
  methods: {
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#Snippets{
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding:0;
  background: #000;
}
.btn-area{
  width: 80%;
  margin: 0 auto;
  padding: 3rem 0;
  text-align:center;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;

}
.btn-area img{
  width: 50%;
  margin: 2rem auto;
}
.btn{
  border-radius: 5px;
  font-size: 2.5rem;
  padding: 1rem 4rem;
  margin-bottom: 3rem;
}
.subhead{
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1rem auto;
}

@media only screen and (max-width: 600px) {
  .btn{
    border-radius: 5px;
    font-size: 1.5rem;
    padding: .75rem 3rem;
    margin-bottom: 1rem;
  }
  .btn-area img{
    width: 80%;
  }
}
</style>
