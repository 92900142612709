<template>
  <div class="nav-bar w-full bg-black py-5">

    <div>
        <a href="/" class="ml-12 flex items-center">
          <img class="logo-brand" src="@/assets/brand-logo.png" />
        </a>
    </div>

    <div class="flex justify-center items-center" v-if="!isMobile">
      <ul class="flex justify-between items-center">
        <li class="mx-2 text-white" v-for="(n,idx) in navigation" :key="idx">
          <a :href="n.href" :class="{'text-theme': $route.name == n.name}">{{n.name}}</a>
          <div class="link-indicator" v-if="$route.name == n.name "></div>
        </li>
      </ul>
    </div>

    <div class="flex justify-end items-center mr-12" v-if="!isMobile">
      <!-- <button class="bg-blue-400 text-white rounded-xl p-3">Follow Us</button> -->
      <a class="social-icon" href="https://etherscan.io/address/quantumfy.eth" target="_blank">
        <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path d="M256,512A256,256,0,0,1,75,75,256,256,0,0,1,437,437a254.33,254.33,0,0,1-181,75Zm0-471.1C137.39,40.9,40.9,137.39,40.9,256S137.39,471.1,256,471.1,471.1,374.61,471.1,256,374.61,40.9,256,40.9Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M253,358.57l-83.75-58.68a1,1,0,0,0-1.45,1.42L251.7,427.1a5.17,5.17,0,0,0,8.6,0l83.87-125.79a1,1,0,0,0-1.45-1.42L259,358.57a5.17,5.17,0,0,1-5.94,0Z" transform="translate(0 0)" style="fill:#7dcacc"/><path d="M355.14,248.07,260.52,77.24a5.17,5.17,0,0,0-9,0L156.86,248.07Z" transform="translate(0 0)" style="fill:#7dcacc"/><path d="M256,248.07h99.14L260.52,77.24A5.12,5.12,0,0,0,256,74.58Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M253,321l-94.6-66.28a5.17,5.17,0,0,1,0-8.47L253,180a5.17,5.17,0,0,1,5.94,0l94.6,66.29a5.17,5.17,0,0,1,0,8.47L259,321A5.17,5.17,0,0,1,253,321Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M259,321l94.6-66.28a5.17,5.17,0,0,0,0-8.47L259,180a5.19,5.19,0,0,0-3-.93V322A5.13,5.13,0,0,0,259,321Z" transform="translate(0 0)" style="fill:#349690"/><path d="M327.54,240.93a5.16,5.16,0,0,1-1.29,7.14h28.89L260.52,77.24A5.12,5.12,0,0,0,256,74.58v37.18Z" transform="translate(0 0)" style="fill:#349690"/><path d="M260.3,427.1l83.87-125.8a1,1,0,0,0-1.45-1.42L259,358.57a5.19,5.19,0,0,1-3,.93v69.9a5.13,5.13,0,0,0,4.3-2.3Z" transform="translate(0 0)" style="fill:#349690"/><path d="M353.57,246.27l-37.7-26.41,11.67,21.07a5.16,5.16,0,0,1-1.3,7.15L256,297.29V322a5.13,5.13,0,0,0,3-.94l94.6-66.28a5.17,5.17,0,0,0,0-8.47Z" transform="translate(0 0)" style="fill:#32908a"/><path d="M342.72,299.89l-44.24,31L256,394.6v34.8a5.13,5.13,0,0,0,4.3-2.3l83.87-125.79A1,1,0,0,0,342.72,299.89Z" transform="translate(0 0)" style="fill:#32908a"/></svg>
      </a>
      <a class="social-icon" href="https://www.instagram.com/quantumfyed" target="_blank">
        <svg id="Capa_2" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 551.034 551.034"><path d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156V386.878c0,90.516,73.64,164.156,164.156,164.156H386.878c90.516,0,164.156-73.64,164.156-164.156V164.156C551.033,73.64,477.393,0,386.878,0ZM495.6,386.878A108.72266,108.72266,0,0,1,386.878,495.6H164.156A108.72265,108.72265,0,0,1,55.434,386.878V164.156A108.722,108.722,0,0,1,164.156,55.434H386.878A108.722,108.722,0,0,1,495.6,164.156l0,222.722Z" style="fill:#2EB9B8"/><path d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516,354.101,133,275.517,133Zm0,229.6A87.083,87.083,0,1,1,362.6,275.517,87.08252,87.08252,0,0,1,275.517,362.6Z" style="fill:#2EB9B8"/><circle cx="418.31" cy="134.07001" r="34.14999" style="fill:#2EB9B8"/></svg>
      </a>
      <a class="social-icon" href="https://bsky.app/profile/quantumfy.org" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 211.243 185.995"><path id="Path_91" data-name="Path 91" d="M55.786,22.175C80,40.356,106.054,77.221,115.619,97.005c9.565-19.782,35.613-56.649,59.832-74.831,17.475-13.119,45.788-23.27,45.788,9.031,0,6.451-3.7,54.191-5.868,61.941-7.54,26.946-35.017,33.818-59.457,29.659,42.722,7.271,53.59,31.356,30.119,55.44-44.576,45.741-64.068-11.477-69.065-26.138-.916-2.688-1.344-3.945-1.35-2.876-.006-1.069-.435.188-1.35,2.876-4.995,14.661-24.487,71.88-69.065,26.138-23.471-24.084-12.6-48.17,30.119-55.44-24.441,4.16-51.918-2.713-59.457-29.659C13.7,85.4,10,37.655,10,31.205c0-32.3,28.314-22.15,45.788-9.031Z" transform="translate(-9.997 -9.655)" fill="#2eb8b9"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Learn', href: '/learn', current: false },
  { name: 'Snippets', href: '/snippets', current: false },
  { name: 'About', href: '/about', current: false }
]
// navigation['current'] = this.$router.name == navigation['name'] ? true : false

export default {
  components: {
  },
  props:['isMobile'],
  setup() {
    return {
      navigation,
    }
  },
}
</script>
<style scoped>
.link-indicator{
  width: 100%;
  height: .2rem;
  background-color: #2EB9B8;
}
.logo-text{
  font-family: 'All Round Gothic';
}
.nav-bar{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
}
.logo-brand{
  min-width: 10rem;
  width: 10rem;
}
.social-icon{
  width: 1.75rem;
  margin: 0 .5rem;
}
.social-icon:hover{
  padding-bottom: .5rem;
  border-bottom: 2px solid #fff;
  transition: 1s;
}
@media only screen and (max-width: 900px) {
  .logo-text{
    /* display: none; */
  }
}
</style>
