<template>
  <div id="Home">
    <Hero />
    <HomeAbout />
    <LatestPost />
    <!-- <Web3Quantum /> -->
    <SnippetsOnHome />
    <JoinEmailList />
  </div>
</template>

<script>
// import axios from 'axios'
// import { gsap, ScrollToPlugin, Draggable, MotionPathPlugin } from "gsap/all";
import Hero from '@/components/home/Hero.vue'
import HomeAbout from '@/components/home/HomeAbout.vue'
import LatestPost from '@/components/home/LatestPost.vue'
// import Web3Quantum from '@/components/home/Web3Quantum.vue'
import SnippetsOnHome from '@/components/home/SnippetsOnHome.vue'
import JoinEmailList from '@/components/home/JoinEmailList.vue'
// @ is an alias to /src

// gsap.from(".hero-info", .5, {x:200, opacity:0});


export default {
  name: "Home",
  components:{
    Hero,
    HomeAbout,
    LatestPost,
    // Web3Quantum,
    SnippetsOnHome,
    JoinEmailList
  },
  data(){
    return{
      email: '',
      feedback:'',
      feedtype:'',
      processing: false,
      education_deck:[],
      news_deck:[]
    }
  },
  methods: {
    // sendEmail(){
    //   if(!this.email){
    //     this.feedback= "You must enter a valid email!"
    //     this.feedtype= "danger"
    //   }else{
    //     if(this.email.includes('@') && this.email.includes('.')){
    //       axios.post('https://api.quantumnews.io/newsletter', {
    //         email: this.email.toLowerCase()
    //       }).then((res)=>{
    //           this.feedback=res.data.feedback
    //           this.feedtype=res.data.feedtype
    //           // alert(res.data.dull_list)
    //       }).catch((err) => {
    //         this.error = err
    //       })
    //     }else{
    //       this.feedback = "You must enter a valid email!"
    //       this.feedtype= "danger"
    //     }
    //   }
    // },
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#Home{
  width: 100%;
  min-height: 100vh;
  margin: 0;
}
</style>
