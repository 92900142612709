<template>
  <div id="Hero">

    <div class="content-hero w-11/12">
      <div class="hero-content-area">
        <h1 class="hero-text text-white text-2xl ">WELCOME TO <span class="text-theme">QUANTUMFY</span></h1>

        <p class="hero-subtext my-4">Your journey towards learning quantum computing begins! </p>

        <p class="hero-subtext my-4">We make quantum simple. <span class="text-theme">#getquantumfyed</span></p>

        <div class="mt-10">
          <a href="/learn" class="select-btn mx-3 p-3 text-white">Learn</a>
          <a href="/snippets" class="select-btn select-btn2 border border-blue-700 p-3 text-blue-700" >Snippets</a>
        </div>

      </div>

      <!-- image area -->
      <div class="relative">
        <img class="media-img media-1" src="https://firebasestorage.googleapis.com/v0/b/quantum-collective.appspot.com/o/education_deck%2F0-timeless%2F3.png?alt=media&token=342d2867-047c-4f28-a082-92b528c210e6" />
        <img class="media-img media-2" src="https://firebasestorage.googleapis.com/v0/b/quantum-collective.appspot.com/o/education_deck%2F0-timeless%2F1.png?alt=media&token=201498ea-4f87-4aef-a954-8569bc9809ee" />
        <img class="media-img media-3" src="https://firebasestorage.googleapis.com/v0/b/quantum-collective.appspot.com/o/education_deck%2F0-timeless%2F4.png?alt=media&token=d5fe6980-f570-41a5-a8d4-0a46a9837eff" />
      </div>
    </div>

    <ScrollIndicator />
  </div>
</template>

<script>
// import axios from 'axios'
import ScrollIndicator from '@/components/snippets/ScrollIndicator.vue'

export default {
  name: "Hero",
  components:{
    ScrollIndicator
  },
  data(){
    return{
      roadmap_list: [
        {icon: require('@/assets/logos/learn.png'), name: 'Learn', link: '/learn'},
        {icon: require('@/assets/logos/learn.png'), name: 'Definitions', link: '/definitions'},
        {icon: require('@/assets/logos/snippets.png'), name: 'News Snippets', link: '/snippets'},
      ]
    }
  },
  methods: {
    gotoLink(link){
      window.open(`${link}`, '_self');
    }
  },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
#Hero{
  width: 100%;
  position: relative;
  min-height: 95vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.hero-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  font-size: 3rem;
}
.hero-subtext{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color:#fff;
}
.select-btn{
  background-color: var(--primary);
  border-radius: 45px;
  padding: .75rem 2.3rem;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.select-btn2{
  border: 2px solid var(--primary);
  border-radius: 45px;
  background: transparent;
  color: var(--primary);
}
.content-hero{
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
}
.media-img{
  position: absolute;
  top: -40%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.media-3{
  box-shadow: 1px -1px 50px -1px rgba(33,214,200,0.75);
  -webkit-box-shadow: 1px -1px 50px -1px rgba(33,214,200,0.75);
  -moz-box-shadow: 1px -1px 50px -1px rgba(33,214,200,0.75);
}
.media-2{
  transform: rotate(30deg) translate(60%, -50px) scale(.75);
}
.media-1{
  transform: rotate(-30deg) translate(-60%, -50px) scale(.75);
}
.service-area{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
@media only screen and (min-width: 1200px) {
  .media-img{
    top: -70%;
    width: 30%;
  }
}
@media only screen and (max-width: 900px) {
  .content-hero{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    width: 80%;
    margin: 0 auto;
    /* padding-bottom: 0rem; */
  }
  .hero-content-area{
    padding: 3rem 0;
  }
  .hero-text{
    line-height: 40px;
    font-weight: 900;
    font-size: 2.3rem;
  }
  .hero-subtext{
    font-size: 9px;
    color:#fff;
  }
  .media-img{
    top: 15%;
    left: 0;
    right: 0;
    width: 30%;
    max-width: 15rem;
    min-width: 12rem;
  }
  /* .media-2{
    transform: rotate(30deg) translate(60%, -50px) scale(.75);
  }
  .media-1{
    transform: rotate(-30deg) translate(-60%, -50px) scale(.75);
  } */
}

@media only screen and (max-width: 600px) {
  .media-img{
    width: 30%;
    max-width: 13rem;
    min-width: 10rem;
  }
}
</style>
