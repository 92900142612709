<template>
  <div id="Sidenav" :class="{'active-drop': showDropdown}">

      <XyzTransition appear >
        <div class="make-hidden" v-if="showDropdown">

        <div class="flex flex-col ">
          <a class="social-icon xyz-nested" xyz="fade small stagger" href="https://etherscan.io/address/0x807530C3af57e8f66340D1359fE4281C89C6654B">
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,512A256,256,0,0,1,75,75,256,256,0,0,1,437,437a254.33,254.33,0,0,1-181,75Zm0-471.1C137.39,40.9,40.9,137.39,40.9,256S137.39,471.1,256,471.1,471.1,374.61,471.1,256,374.61,40.9,256,40.9Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M253,358.57l-83.75-58.68a1,1,0,0,0-1.45,1.42L251.7,427.1a5.17,5.17,0,0,0,8.6,0l83.87-125.79a1,1,0,0,0-1.45-1.42L259,358.57a5.17,5.17,0,0,1-5.94,0Z" transform="translate(0 0)" style="fill:#7dcacc"/><path d="M355.14,248.07,260.52,77.24a5.17,5.17,0,0,0-9,0L156.86,248.07Z" transform="translate(0 0)" style="fill:#7dcacc"/><path d="M256,248.07h99.14L260.52,77.24A5.12,5.12,0,0,0,256,74.58Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M253,321l-94.6-66.28a5.17,5.17,0,0,1,0-8.47L253,180a5.17,5.17,0,0,1,5.94,0l94.6,66.29a5.17,5.17,0,0,1,0,8.47L259,321A5.17,5.17,0,0,1,253,321Z" transform="translate(0 0)" style="fill:#2eb9b8"/><path d="M259,321l94.6-66.28a5.17,5.17,0,0,0,0-8.47L259,180a5.19,5.19,0,0,0-3-.93V322A5.13,5.13,0,0,0,259,321Z" transform="translate(0 0)" style="fill:#349690"/><path d="M327.54,240.93a5.16,5.16,0,0,1-1.29,7.14h28.89L260.52,77.24A5.12,5.12,0,0,0,256,74.58v37.18Z" transform="translate(0 0)" style="fill:#349690"/><path d="M260.3,427.1l83.87-125.8a1,1,0,0,0-1.45-1.42L259,358.57a5.19,5.19,0,0,1-3,.93v69.9a5.13,5.13,0,0,0,4.3-2.3Z" transform="translate(0 0)" style="fill:#349690"/><path d="M353.57,246.27l-37.7-26.41,11.67,21.07a5.16,5.16,0,0,1-1.3,7.15L256,297.29V322a5.13,5.13,0,0,0,3-.94l94.6-66.28a5.17,5.17,0,0,0,0-8.47Z" transform="translate(0 0)" style="fill:#32908a"/><path d="M342.72,299.89l-44.24,31L256,394.6v34.8a5.13,5.13,0,0,0,4.3-2.3l83.87-125.79A1,1,0,0,0,342.72,299.89Z" transform="translate(0 0)" style="fill:#32908a"/></svg>
          </a>
          <a class="social-icon xyz-nested" xyz="fade small stagger" href="https://www.instagram.com/quantumfyed/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 551.034 551.034"><path d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156V386.878c0,90.516,73.64,164.156,164.156,164.156H386.878c90.516,0,164.156-73.64,164.156-164.156V164.156C551.033,73.64,477.393,0,386.878,0ZM495.6,386.878A108.72266,108.72266,0,0,1,386.878,495.6H164.156A108.72265,108.72265,0,0,1,55.434,386.878V164.156A108.722,108.722,0,0,1,164.156,55.434H386.878A108.722,108.722,0,0,1,495.6,164.156l0,222.722Z" style="fill:#2EB9B8"/><path d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516,354.101,133,275.517,133Zm0,229.6A87.083,87.083,0,1,1,362.6,275.517,87.08252,87.08252,0,0,1,275.517,362.6Z" style="fill:#2EB9B8"/><circle cx="418.31" cy="134.07001" r="34.14999" style="fill:#2EB9B8"/></svg>
          </a>
          <a class="social-icon xyz-nested" xyz="fade small stagger" href="https://twitter.com/quantumfyed">
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 416"><path class="cls-1" d="M512,97.25a218.64,218.64,0,0,1-60.48,16.57,104.36,104.36,0,0,0,46.18-58,210,210,0,0,1-66.56,25.41A105,105,0,0,0,249.57,153,108,108,0,0,0,252,176.93C164.74,172.67,87.52,130.85,35.65,67.14A105,105,0,0,0,67.9,207.42,103.69,103.69,0,0,1,20.48,194.5v1.15a105.43,105.43,0,0,0,84.1,103.13,104.65,104.65,0,0,1-27.52,3.46,92.77,92.77,0,0,1-19.88-1.79c13.6,41.57,52.2,72.13,98.08,73.12A210.93,210.93,0,0,1,25.12,418.34,197.72,197.72,0,0,1,0,416.9,295.54,295.54,0,0,0,161,464c193.16,0,298.76-160,298.76-298.69,0-4.64-.16-9.12-.39-13.57A209.29,209.29,0,0,0,512,97.25Z" transform="translate(0 -48)" style="fill:#2EB9B8"/></svg>
          </a>
        </div>

        <hr />

        <div class="roadmap-pod xyz-nested" xyz="fade small stagger">
          <a href="/learn">
            <!-- <img src="@/assets/logos/learn.png" /> -->
            <p class="hero-text">Learn</p>
          </a>
        </div>

        <div class="roadmap-pod xyz-nested" xyz="fade small stagger">
          <a href="/snippets">
          <!-- <img src="@/assets/logos/snippets.png" /> -->
          <p class="hero-text">Snippets</p>
        </a>
        </div>

        <div class="roadmap-pod xyz-nested" xyz="fade small stagger">
          <a href="/">
            <img src="@/assets/icons/menu/home.svg" />
          </a>
        </div>

      </div>
      </XyzTransition>

      <div class="roadmap-pod main-pod" @click="activateMenu()">
      <img class="drop-indicator" src="@/assets/icons/menu/down.svg" />
          <img src="@/assets/qn-logo-white.svg" class="learn-svg"/>
      </div>
  </div>
</template>

<script>

export default {
  name: "Sidenav",
  data(){
    return{
      showDropdown: false,
    }
  },
  methods: {
    activateMenu(){
      this.showDropdown = !this.showDropdown
    }
  },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#Sidenav{
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1.5rem;
  bottom: 0;
  z-index: 10;
  transition:1.5s;
}
.active-drop{
  padding: .5rem;
  background: rgba( 255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 1px );
  -webkit-backdrop-filter: blur( 1px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: .2rem auto;
  z-index: 10;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  border-radius: 5px;
}
.main-pod{
  background-color: var(--primary);
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.drop-indicator{
  position: absolute;
  top: -2rem;
  transform: rotate(180deg);
  width: 15px !important;
  margin : .5rem;
  margin-bottom: 1rem;
}
.social-icon{
  margin: 1.2rem auto;
  width: 2.2rem;
}
.hero-text{
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  color: #fff;
  font-size: 2rem;
}
.roadmap-pod{
  margin: 1.5rem 0;
  text-align: center;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 500;
  transition:1s;
}
.coming-soon-text{
  color: #8e8e8e;
}
.roadmap-pod a{
  color: #fff;
}
.roadmap-pod img{
  width: 2.5rem;
  transition:1s;
}
.active-drop p{
  font-size: .6rem;
}
.roadmap-pod  .learn-svg{
  width: 1.8rem;
}
.make-hidden .roadmap-pod img:hover{
  transform: translateY(-10px);
}

/* Drop down activated */
.active-drop .drop-indicator{
  transform: rotate(180deg);
}
@media only screen and (max-width: 600px) {
}
</style>
