<template>
<footer id="footer" class="relative pt-10">
    <div class="py-0 mx-auto container px-4 flex tems-center">
                <div class="w-full lg:mb-0 flex tems-center">
                    <div class="w-full px-2">
                        <ul>
                            <li class="m-4 p-3" v-for="(link, idx) in products" :key="idx">
                                <a class="text-lg leading-none text-white" :href="link.link">{{link.label}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="w-full px-6">
                        <ul>
                            <li class="m-4 p-3" v-for="(link, idx) in resources" :key="idx">
                                <a class="text-lg leading-none text-white" :href="link.link">{{link.label}}</a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="w-1/2 flex items-start justify-end">
                    <div class="w-full flex justify-between">
                        <div class="flex flex-col md:flex-row items-center space-x-4 bg-theme p-2 rounded-md" >
                            <a href="https://www.facebook.com/quantumfyed" target="_blank" class="my-3 md:my-0">
                              <img src="@/assets/icons/social-media/facebook.svg" class="social-icon"/>
                            </a>
                            <a href="https://www.instagram.com/quantumfyed" target="_blank" class="my-3 md:my-0">
                              <img src="@/assets/icons/social-media/instagram.svg" class="social-icon"/>
                            </a>
                            <a href="https://bsky.app/profile/quantumfy.org" target="_blank" class="my-3 md:my-0 px-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 211.243 185.995"><path id="Path_91" data-name="Path 91" d="M55.786,22.175C80,40.356,106.054,77.221,115.619,97.005c9.565-19.782,35.613-56.649,59.832-74.831,17.475-13.119,45.788-23.27,45.788,9.031,0,6.451-3.7,54.191-5.868,61.941-7.54,26.946-35.017,33.818-59.457,29.659,42.722,7.271,53.59,31.356,30.119,55.44-44.576,45.741-64.068-11.477-69.065-26.138-.916-2.688-1.344-3.945-1.35-2.876-.006-1.069-.435.188-1.35,2.876-4.995,14.661-24.487,71.88-69.065,26.138-23.471-24.084-12.6-48.17,30.119-55.44-24.441,4.16-51.918-2.713-59.457-29.659C13.7,85.4,10,37.655,10,31.205c0-32.3,28.314-22.15,45.788-9.031Z" transform="translate(-9.997 -9.655)" fill="#fff"/>
        </svg>
                            </a>
                        </div>

                    </div>
                </div>
    </div>
    <div class="py-16 flex flex-col justify-center items-center text-center">
        <img src="@/assets/qn-logo-white.svg" class="w-24 brand-logo"/>
        <p class="mt-6 text-lg leading-none text-white">
            Copyright © Quantumfy 2020 - 2023 <br /><br /> All Rights Reserved
        </p>
    </div>
</footer>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            products: [
                {label: 'Learn', link: '/learn'},
                {label: 'Snippets', link: 'https://t.me/quantumfy'}
            ],
            resources: [
                {label: 'About Us', link: '/about'},
                {label: 'FAQ', link: '/faq'},
                {label: 'Contact', link: '/contact'}
            ]
        }
    },
    mounted() {},
    methods: {
    },
};
</script>

<style scoped>
#footer{
    border-top: 3px solid #5ad9d9;
    background-color: #008080;
}
.social-icon{
    width: 2rem;
    margin: 0 1rem;
}
</style>
