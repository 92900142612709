<template>
  <div id="InfographicSlider">

    <div class="section-toolbar">
      <h2 class="head-text uppercase"><span class="text-theme">Info</span>graphics</h2>
      <p class="text-white">Come learn about quantum computing from our array of infographics</p>
    </div>

    <div class="slider">
      <img v-for="(im, idx) in infographics" :key="idx" :src="im.image_url" @click="enlargeImg(im.image_url)"/>
      <!-- <div
        class="infographic"
        v-for="(im, idx) in infographics" :key="idx"
        :style="{'background-image': 'url(' + im.image_url + ')'}"></div> -->
    </div>

    <EnlargedGraphic v-if="showEnlargedGraphic" :media="current_img" @enlargeImg="enlargeImg"/>
  </div>
</template>
<script>
// import axios from 'axios'
import timeless_graphics_json from '@/assets/json/timeless_graphics.json'
import EnlargedGraphic from '@/components/learn/EnlargedGraphic.vue'

export default {
  name: "InfographicSlider",
  components:{
    EnlargedGraphic
  },
  data(){
    return{
      infographics: timeless_graphics_json,
      showEnlargedGraphic: false,
      current_img: ''
    }
  },
  methods: {
    enlargeImg(img_url){
      this.current_img = img_url
      this.showEnlargedGraphic  = !this.showEnlargedGraphic
    }
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

#InfographicSlider{
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0;
}

.head-text{
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  font-size: 3rem;
}
.slider{
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin: 3rem 0;
}
.slider img{
  width: 20%;

  position:relative;
  margin: .5rem;
  border-radius: 5px;
  border: 2px solid #fff;
  transition: 1s;
}
/* .infographic{
  position:relative;
  width: 220px;
  height: 400px;
  overflow:hidden;
  margin: 0 .5rem;
  border-radius: 5px;
  border: 5px solid #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  transition: 1s;
} */
@media only screen and (max-width: 600px) {
  .slider img{
    width: 25%;
  }
  .slider{
    margin: 3rem auto;
  }
}
</style>
