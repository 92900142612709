<template>
  <div id="InfographicCarousel">
    <section class="info-area mt-10">
      <div class="info-box flex flex-col justify-center" v-for="(caro, idx) in carousel_gfx" :key="idx">
        <img :src="caro.thumbnail"/>
        <div class="w-7/10 flex items-center justify-evenly mx-auto">
          <div class="bg-white rounded-full w-3 h-3 mt-5" v-for="(num,idx) in caro.pages" :key="idx + 'd'"></div>
        </div>
          <button class="mx-auto mt-6 p-2 px-5 bg-theme text-white text-md font-bold rounded-md"
            @click="toggleCarousel(caro)">View</button>
      </div>
    </section>

    <EnlargedCarousel v-if="showEnlargedCarousel" :media="current_carousel" @toggleCarousel="toggleCarousel"/>
  </div>
</template>
<script>
// import axios from 'axios'
import carousel_graphics_json from '@/assets/json/carousel_graphics.json'
import EnlargedCarousel from '@/components/learn/EnlargedCarousel.vue'

export default {
  name: "InfographicCarousel",
  components:{
    EnlargedCarousel
  },
  data(){
    return{
      carousel_gfx: carousel_graphics_json,
      showEnlargedCarousel: false,
      current_carousel: {}
    }
  },
  methods: {
    toggleCarousel(caro){
      this.current_carousel = caro
      this.showEnlargedCarousel  = !this.showEnlargedCarousel
    }
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#InfographicCarousel{
  width: 90%;
  margin: 0 auto;
}
.info-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  justify-items: center;
  align-items: center;
  width: 80%;
  margin: 3rem 0;
}

.info-area{
  margin: 4rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  justify-items: center;
  align-items: center;
}
/* 2D - 3D test */
.info-box{
  position: relative;
  width: 250px;
  height: 250px;
  transform-style: perserve-3d;
  perspective: 100px;
}
.info-box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
  z-index: calc(1 + var(--i));
  opacity: calc(1 - (0.2 * var(--j)));
}
@media only screen and (max-width: 600px) {
  .info-box{
    position: relative;
    width: 175px;
    height: 175px;
  }
  .info-area{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  /* .info-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem 0;
  }
  .infographic{
    position:relative;
    width: 175px;
    height: 175px;
    margin: .5rem;
    border: 2px solid #fff;
  } */
}
</style>
