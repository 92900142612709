<template>
  <a href="#" class="scroll-down">
    <div class="mouse">
      <span></span>
    </div>
    <div class="arrow">
      <span></span>
    </div>
  </a>
</template>
<script>
  export default{

  }
</script>
<style scoped>
.scroll-down{
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}
.mouse{
  width: 2rem;
  height: 3.5rem;
  border: 2px solid #fff;
  margin-bottom: .5rem;
  border-radius: 2.5rem;
}
.mouse span{
  width: .7rem;
  height: .7rem;
  background-color: #fff;
  display: block;
  border-radius: 50%;
  margin: auto;
  animation: move-wheel 1.5s linear infinite;
}
.arrow span{
  display: block;
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  transform: rotate(45deg);
  animation: arrow-down .6s alternate infinite;
}

@keyframes move-wheel{
  0%{
    opacity: 0;
    transform: translateY(0rem);
  }
  100%{
    opacity: 1;
    transform: translateY(2rem);
  }
}
@keyframes arrow-down{
  0%{
    opacity: 0;
  }
  25%{
    opacity: .25;
  }
  50%{
    opacity: .5;
  }
  75%{
    opacity: .75;
  }
  100%{
    opacity: 1;
  }
}
</style>
