<template>
  <div id="InfographicSlider">

    <div class="section-toolbar">
      <h2 class="head-text uppercase"><span class="text-theme">Quantum</span> facts</h2>
      <p class="text-white">Come learn about quantum computing from our array of infographics</p>
    </div>

    <section class="info-area">
      <div class="info-box" v-for="(im, idx) in facts_gfx" :key="idx+'gate'">
        <img :src="im.image_url"/>
      </div>
    </section>

    <div class="section-toolbar">
      <h2 class="head-text uppercase"><span class="text-theme">Quantum</span> gates</h2>
      <p class="text-white">Come learn about quantum computing from our array of infographics</p>
    </div>

    <!-- <div class="info-grid">
      <div
        class="infographic"
        v-for="(im, idx) in gate_gfx" :key="idx"
        :style="{'background-image': 'url(' + im.image_url + ')'}"></div>
    </div> -->
    <section class="info-area">
      <div class="info-box" v-for="(im, idx) in gate_gfx" :key="idx+'gate'">
        <img :src="im.image_url" />
      </div>
    </section>

  </div>
</template>
<script>
// import axios from 'axios'
import box_graphics_json from '@/assets/json/box_graphics.json'
import gate_graphics_json from '@/assets/json/gate_graphics.json'

export default {
  name: "InfographicSlider",
  components:{
  },
  data(){
    return{
      facts_gfx: box_graphics_json,
      gate_gfx: gate_graphics_json,
    }
  },
  methods: {
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#InfographicSlider{
  width: 90%;
  margin: 0 auto;
}
.head-text{
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  font-size: 3rem;
}
.info-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  justify-items: center;
  align-items: center;
  width: 80%;
  margin: 3rem 0;
}
.infographic{
  position:relative;
  width: 300px;
  height: 300px;
  overflow:hidden;
  margin: 0 .5rem;
  border-radius: 5px;
  border: 5px solid #fff;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  transition: 1s;
}
.info-area{
  margin: 4rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;

  justify-items: center;
  align-items: center;
}
/* 2D - 3D test */
.info-box{
  position: relative;
  width: 250px;
  height: 250px;
  transform-style: perserve-3d;
  perspective: 100px;
}
.info-box img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
  z-index: calc(1 + var(--i));
  opacity: calc(1 - (0.2 * var(--j)));
}
.info-box:hover img{
  transform: rotate(-30deg) skew(20deg) translateX(calc(20px * var(--i))) translateY(calc(-20px * var(--i)))scale(.9);
  box-shadow: -20px 20px 20px rgba(225,225,225,0.25);
  /* transform: scale(1.9); */
}
@media only screen and (max-width: 600px) {
  .info-box{
    position: relative;
    width: 175px;
    height: 175px;
  }
  .info-area{
    margin: 4rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem 0;
  }
  /* .info-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem 0;
  }
  .infographic{
    position:relative;
    width: 175px;
    height: 175px;
    margin: .5rem;
    border: 2px solid #fff;
  } */
}
</style>
