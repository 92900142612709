<template>
  <div id="LatestPost">
    <h2 class="text-theme uppercase text-3xl">LATEST</h2>

    <div class="flex overflow-x-scroll inline-block nowrap justify-evenly pl-5 text-left w-11/12">

      <div v-for="(b,idx) in box_graphics" :key="idx">
        <img class="latest-img" :src="b.image_url" />
        <div class="ml-2">
          <p class="text-white text-xl font-bold">{{b.title}}</p>
          <p class="text-gray-500">{{ simplfyDate(b.created) }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import axios from 'axios'
import box_json from '@/assets/json/box_graphics.json'

export default {
  name: "LatestPost",
  components:{
  },
  data(){
    return{
      box_graphics: box_json
    }
  },
  methods: {
    gotoLink(link){
      window.open(`${link}`, '_self');
    },
    simplfyDate(epoch) {
      let simplfied_time;
      const now = new Date()
      const utc_epoch = Math.round(now.getTime() / 1000)
      let time_ago = utc_epoch - epoch
      if (time_ago < 180) { //3mins or less
        simplfied_time = `now`
      } else if (time_ago >= 180 && time_ago < 3600) {
        let min = time_ago / 60 //by mins until hour
        if (min == 1 && min < 2) {
          simplfied_time = `${min.toFixed(0)} min ago`
        } else {
          simplfied_time = `${min.toFixed(0)} mins ago`
        }
      } else if (time_ago >= 3600 && time_ago < 86400) {
        let hour = time_ago / 3600 //by mins until hour
        if (hour == 1 && hour < 2) {
          simplfied_time = `${hour.toFixed(0)} hour ago`
        } else {
          simplfied_time = `${hour.toFixed(0)} hours ago`
        }
      } else if (time_ago >= 86400 && time_ago < 604800) {
        let day = time_ago / 86400
        if (day == 1 && day < 2) {
          simplfied_time = `${day.toFixed(0)} day ago`
        } else {
          simplfied_time = `${day.toFixed(0)} days ago`
        }
      } else if (time_ago >= 604800 && time_ago < 1814400) {
        let week = time_ago / 604800
        if (week >= 1 && week < 2) {
          simplfied_time = `${week.toFixed(0)} week ago`
        } else {
          simplfied_time = `${week.toFixed(0)} weeks ago`
        }
      } else if (time_ago >= 1814400 && time_ago < 31556952) {
        let month = time_ago / 1814400
        if (month >= 1 && month < 2) {
          simplfied_time = `${month.toFixed(0)} month ago`
        } else {
          simplfied_time = `${month.toFixed(0)} months ago`
        }
      } else if (time_ago >= 31556952) {
        let year = time_ago / 31556952
        if (year == 1 && year < 2) {
          simplfied_time = `${year.toFixed(0)} year ago`
        } else {
          simplfied_time = `${year.toFixed(0)} years ago`
        }
      }
      return simplfied_time
    }
  },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

#LatestPost{
  width: 100%;
  position: relative;
  min-height: 90vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: .4rem;
  font-weight: 500;
  font-size: 2rem;
  color:#fff;
}
.latest-img{
  min-width: 15rem;
}
@media only screen and (max-width: 900px) {
  #LatestPost{
    min-height: 60vh;
  }

}
</style>
