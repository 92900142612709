<template>
  <div id="LearnHero">
    <!-- <img class="page-logo" src="@/assets/logos/learn.png" /> -->
    <h2 class="hero-text">LEARN</h2>
    <p class="announce">Come learn about quantum computing from our array of infographics</p>

    <button class="select-btn mx-3 p-3 text-white">More Coming Soon!</button>
    <!-- <div>
      <a href="/learn" class="select-btn mx-3 p-3 text-white">Learn</a>
      <a href="/snippets" class="select-btn select-btn2 border border-blue-700 p-3 text-blue-700" >Snippets</a>
    </div> -->

    <ScrollIndicator />
  </div>
</template>
<script>
// import axios from 'axios'
import ScrollIndicator from '@/components/snippets/ScrollIndicator.vue'
export default {
  name: "LearnHero",
  components:{
    ScrollIndicator
  },
  data(){
    return{
      img: require('@/assets/media/insta.jpg')
    }
  },
  methods: {
  },
created(){

}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

#LearnHero{
  width: 100%;
  margin: 0;
  min-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 1s;
}
.hero-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  font-size: 3.5rem;
}
.select-btn{
  background-color: var(--primary);
  border-radius: 35px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.select-btn2{
  border:2px solid var(--primary);
  border-radius: 45px;
  background: transparent;
  color: var(--primary);
}
.announce{
  color: #fff;
  font-size: 2.3rem;
  width: 80%;
  margin: 3rem auto;
  transition: 1s;
}
.page-logo{
  max-width: 20rem;
  width: 20%;
  margin: 4rem;
  transition: 1s;
}

@media only screen and (max-width: 600px) {
  .announce{
    width: 60%;
    font-size: 1.2rem;
  }
  .page-logo{
    min-width: 5rem;
    margin: 2rem;
    width: 20%;
  }
}
</style>
