<template>
  <div id="Learn">
    <LearnHero />
    <InfographicSlider />
    <InfographicBoxes />

    <div class="w-9/10 mx-auto my-3 mb-10">
      <h2 class="head-text uppercase"><span class="text-theme">Quantum</span> carousel</h2>
      <p class="note text-white">Click <span class="text-theme">view</span> under a carousel post  to see more.</p>
    </div>
    <InfographicCarousel />
    <!-- <MediaKit /> -->
  </div>
</template>

<script>
// import axios from 'axios'
import LearnHero from '@/components/learn/LearnHero.vue'
import InfographicSlider from '@/components/learn/InfographicSlider.vue'
import InfographicBoxes from '@/components/learn/InfographicBoxes.vue'
// import MediaKit from '@/components/learn/MediaKit.vue'
import InfographicCarousel from '@/components/learn/InfographicCarousel.vue'

export default {
  name: "Learn",
  components:{
    LearnHero,
    InfographicSlider,
    InfographicBoxes,
    InfographicCarousel,
    // MediaKit
  },
  data(){
    return{
    }
  },
  methods: {
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#Learn{
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding:0;
  padding-bottom: 3rem;
  background: #000;
}

.head-text{
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  font-size: 3rem;
}
</style>
