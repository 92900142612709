<template>
	<div id="JoinEmailList" class="flex items-center justify-center">
		<div class="email-area text-white border-white border-2 rounded-2xl border shadow-xl p-6 md:p-10 w-10/12 md:w-1/2 lg:w-1/3">
			<div class="flex flex-col items-center space-y-4">
				<h2 class="text-theme uppercase text-xl md:text-3xl">BE THE FIRST!</h2>

				<p class="text-sm md:text-lg text-gray-100 text-center w-5/6">
					We will send out quantum information snippets directly to your inbox, wrapped with our quantum love! Being on our email list you will also be one of the first to get access to news and first chance to get acces to our events
				</p>
				<p v-if="status == 'success'" class="my-2 text-red-400" :class="{'text-green-300': status == 'success', 'text-red-400': status != 'success'}">{{feedback}} </p>

				<div class="flex w-full" v-if="!processing && status != 'success'">
					<input type="text" placeholder="Email" class="email-input border-2 rounded-lg w-2/3 h-12 px-4" v-model="email">

					<button class="subscribe-btn text-white rounded-md font-semibold px-4 py-3 w-1/3 mx-2" @click.prevent="sendEmail()">Subscribe</button>
				</div>
				<div v-else-if="processing">
					<iframe src="https://embed.lottiefiles.com/animation/98288"></iframe>
				</div>
				
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref } from 'vue'
import axios from 'axios'

const email = ref('')
const feedback = ref('')
const status = ref('')
const processing = ref(false)

const sendEmail = () => {
	processing.value = true
	if(email.value == ''){
		feedback.value = 'You need to enter an email address'
	}else{
		const payload = {
			email: email.value
		}
		axios.post('https://api.q6fy.org/2024-10/site/email', payload
		).then((res) =>{
			processing.value = false
			status.value = 'success'
			console.log("res.data", res.data)
			if(res.data.created){
				feedback.value = "Thank you for joining our customer list! Be on the look out for our newletter in your email!"
			}
		}).catch((err) =>{
			processing.value = false
			console.log(err)
			feedback.value = 'The has been an error'
		})
	}
}
</script>
<style scoped>
#JoinEmailList{
	min-height: 40vh;
	margin-bottom: 2rem;
}
.subscribe-btn{
	background-color: var(--primary);
}
.email-input{
	background-color: #132120;
}
</style>
