<template>
  <div id="SnippetsHero">
    <h2 class="hero-text">SNIPPETS</h2>
    <p class="announce ">Keeping up with the QuantumVerse is HARD!</p>
    <p class="announce">Join our news snippets telegram channel where we share news and
      resources from the world of quantum!</p>

    <div>
      <a href="https://t.me/quantumfy" class="select-btn bg-blue-700 mx-3 p-3 text-white">JOIN CHANNEL</a>
    </div>

    <ScrollIndicator />
  </div>
</template>
<script>
// import axios from 'axios'
import ScrollIndicator from '@/components/snippets/ScrollIndicator.vue'

export default {
  name: "SnippetsHero",
  components:{
    ScrollIndicator
  },
  data(){
    return{
      img: require('@/assets/media/insta.jpg')
    }
  },
  methods: {
  },
created(){
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#SnippetsHero{
  width: 100%;
  margin: 0;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  transition: 1s;
}
.hero-text{
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
.select-btn{
  background-color: var(--primary);
  border-radius: 45px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  padding: 1rem 3rem;
}
.announce{
  color: #fff;
  font-size: 1.8rem;
  width: 60%;
  margin: 1rem auto;
  transition: 1s;
}
.page-logo{
  max-width: 20rem;
  width: 20%;
  margin: 4rem;
  transition: 1s;
}

@media only screen and (max-width: 600px) {
  .announce{
    width: 60%;
    font-size: 1.2rem;
  }
  .page-logo{
    min-width: 5rem;
    margin: 2rem;
    width: 20%;
  }
}
</style>
