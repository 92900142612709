<template>
  <Header :isMobile="isMobile"/>
  <Sidenav v-if="isMobile"/>
    <router-view/>
  <Footer />
</template>
<script>
import Header from '@/components/layout/sections/Header.vue'
import Sidenav from '@/components/layout/sections/Sidenav.vue'
import Footer from '@/components/layout/sections/Footer.vue'

export default{
  components:{
    Header,
    Sidenav,
    Footer
  },
  data(){
    return{
      isMobile: false,
      window:{},
    }
  },
  watch:{
  window:{
    handler(nv){
      if(nv.width < 600){
        this.isMobile = true
        // console.log(nv.width)
      }else{
        this.isMobile = false
      }
    },
    deep: true
  },
  },
  methods:{
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    this.window = {
      'width': window.innerWidth,
      'height': window.innerHeight
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background:#000;
}
:root{
  --primary: #2EB9B8;
}
.text-theme{
  color: #2EB9B8;
}
.bg-theme{
  background-color: #2EB9B8;
}
</style>
