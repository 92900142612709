<template>
<div id="Contact">
  <h1 class="text-white uppercase font-bold text-3xl mb-7">Contact</h1>

  <!-- component -->
  <form class="w-full rounded max-w-lg contact-form" v-if="!processing">
    <p class="my-3 text-green-200">{{feedback}}</p>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">
          Name
        </label>
        <input class="appearance-none block w-full bg-gray-700 text-white rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-600" id="name" type="text" placeholder="John Doe" :class="{'border border-red-500': showReminder}" v-model="name">
        <p class="text-red-500 text-xs italic" v-if="showReminder">Please fill out this field.</p>
      </div>

      <div class="w-full md:w-1/2 px-3">
        <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">
          E-mail
        </label>
        <input class="appearance-none block w-full bg-gray-700 text-white border border-gray-500 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-600 focus:border-gray-500" id="email" type="email" placeholder="me@example.com" :class="{'border border-red-500': showReminder}" v-model="email">
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-password">
          Subject
        </label>
        <input class="appearance-none block w-full bg-gray-700 text-white border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-600 focus:border-gray-500" id="subject" type="text" placeholder="Topic" :class="{'border border-red-500': showReminder}" v-model="subject">
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-password">
          Message
        </label>
        <textarea class="no-resize appearance-none block w-full bg-gray-700 text-white border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-600 focus:border-gray-500 h-48 resize-none" id="message" placeholder="Type a message..."  :class="{'border border-red-500': showReminder}" v-model="message"></textarea>
      </div>
    </div>
    <div class="text-center">
      <div class="w-1/3 mx-auto">
        <button class="contact-btn shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button" @click.prevent="validate()">
          Send
        </button>
      </div>
      <div class="md:w-2/3"></div>
    </div>
  </form>
  <div class="w-2/3 mx-auto" v-else>
    <iframe class="processing-box" src="https://embed.lottiefiles.com/animation/95749"></iframe>
  </div>
</div>
</template>
<script>
import axios from 'axios'
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default{
  props:['isMobile'],
  components:{
  },
data(){
  return{
    name: '',
    email: '',
    subject: '',
    message: '',
    feedback: '',
    status: '',
    processing: false,
    showReminder: false
  }
},
methods:{
  returnToLastRoute(){
    this.$router.back()
  },
  validate(){
    if(this.name == '' || this.email == '' || this.subject == '' || this.message == ''){
      this.showReminder = true
    }else{
      // this.processing = true
      this.sendEmail()
    }
  },
  sendEmail(){
    this.processing = true
    let payload ={
      name:this.name,
      email:this.email,
      subject:this.subject,
      message:this.message
    }
    axios.post('https://api.quantumfy.co/contact', payload)
    .then((res)=>{
      this.name = ''
      this.email = ''
      this.subject= ''
      this.message = ''
      this.feedback = res.data.feedback
      this.status = res.data.status
      this.processing = false
    }).catch((err)=>{
      this.feedback = 'Could not send email'
      this.processing = false
      console.log(err)
    })
  }
}
}
</script>
<style scoped>
#Contact{
  min-height: 90vh;
  font-size: 1rem;
  width: 80%;
  margin: 3rem auto;
}
.contact-form{
  background-color: #222;
  border: 1px solid var(--primary);
  padding: .7rem;
  margin: 0 auto;
  margin-top: 1rem;
}
.processing-box{
  width: 100%;
}
hr{
  border:none;
    border-bottom: 2px solid var(--primary);
}
.back-btn{
  background-color: transparent;
  border:none;
  border-radius: 10px;
  width: 2rem;
  margin: .5rem 1rem ;
}
.back-btn img{
  width: 100%;
}
.setting-icon{
  position: absolute;
  right: 2rem;
  top: .5rem;
  width: 2rem;
}
.contact-area{
  width: 70%;
  margin: 3rem auto;
  text-align:center;
  background-color: #222;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 2rem ;
}
.contact-field input{
  border:none;
  background-color: #333333;
  outline: none;
  color: #fff;
  border-bottom: 2px solid var(--primary);
  padding: .4rem 1rem;
  width: 90%;
  margin: .6rem auto;
  transition: 1s;
  font-size: 1rem;
}
.contact-field textarea{
  width: 90%;
  margin: .6rem auto;
  background-color: #333333;
  outline: none;
  color: #fff;
  padding: .4rem 1rem;
  margin: .6rem 0;
  border:none;
  border-bottom: 2px solid var(--primary);
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  resize: none;
  min-height: 15rem;
  max-height: 15rem;
  font-size: 1.1rem;
}
.contact-btn{
  width: 100%;
  background-color: var(--primary);
  margin: 1rem auto;
}
.feedback{
  color: #ba0d03;
}
.success{
  color: #02c43d;
}
@media only screen and (max-width: 600px) {
  .contact-area{
    width:80%;
  }
  .contact-field input{
    font-size: .8rem;
  }
  .contact-field textarea{
      font-size: .96rem;
  }
}

</style>
