<template>
  <div id="EnlargedGraphic" class="modal-area">
    <div class="modal-overlay" @click="enlargeImg('')"></div>

    <div class="modal-body xyz-nested" xyz="fade short-100% delay-3 ease-out-back">
      <img class="media-image" :src="media" />

      <section class="button-toolbar">

        <span class="exit-btn close-btn " @click="enlargeImg('')">
          <img src="@/assets/graphics/close.svg" /> Close
        </span>
    </section>
    </div>

</div>
</template>
<script>
// import {fs, db} from '@/_firebase/init';
// import {mapMutations} from 'vuex'
export default {
  name: 'EnlargedGraphic',
  props: ['media', ''],
  data(){
    return{
    errors: [],
    }
  },
  methods:{
    // ...mapMutations(['REMOVE_IDX_GALLERY']),
    enlargeImg(){
      this.$emit('enlargeImg', '')
    }
  },
  created(){
    console.log('Image enlarged')
    console.log(this.media)
  }
}
</script>
<style scoped>
#EnlargedGraphic{
  position: fixed;
  top:0;
  left:0;
  z-index: 24;
  width: 100%;
  height: 105%;
  display:flex;
  align-items:center;
  justify-content:center;
  overflow: hidden;
  transition:1s;
}
.modal-overlay{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #222;
  width: 100%;
  height: 110%;
  opacity: .7;
  cursor:pointer;
}
.modal-body{
  max-width: 80%;
  margin: 20% auto;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  position: relative;
}
.media-image{
  width: 100%;
  min-width: 20rem;
  max-width: 60rem;
  height: 50%;
  overflow:hidden;
  text-align: center;
  overflow: auto;
  transition:1s;
}
.button-toolbar{
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 1px );
  -webkit-backdrop-filter: blur( 1px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  width: 80%;
  height: 6rem;
  margin: .2rem auto;
  z-index: 10;
  display:flex;
  align-items:center;
  justify-content: space-evenly;
  border-radius: 5px;
}
.exit-btn{
  bottom: 1rem;
  right: 1rem;
  /* position: absolute; */
  display:flex;
  align-items:center;
  justify-content:center;
  font-size: 1.5rem;
  background-color: #E3E3E3;
  color: #777;
  border: 3px solid #777;
  border-radius: 5px;
  padding: 0rem 1rem;
  cursor:pointer;
  transition:1s;
}
.exit-btn img{
  margin: .5rem 1rem;
  width: 2rem;
  height: 2rem;
}
.delete-btn{
  background-color: #EBADAD;
  color: #B52A2A;
  border: 3px solid #B52A2A;
}
body[data-theme='dark'] .modal-body{
  background-color: var(--bg-dark);
  color: var(--text-dark);
}
@media only screen and (max-width: 600px) {
  .media-image{
    min-width: 15rem;
    max-width: 40rem;
    /* height: 20%; */
  }
}
</style>
